<template>
  <div>
    <Navbar />

    <PageTitle
      :title="'Profile'"
      :subtitle="'Information about your profile'"
    />

    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-7">
            <div class="card has-margin-bottom-50">
              <header class="card-header">
                <p class="card-header-title">
                  User information
                </p>
              </header>
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <figure class="image is-48x48">
                      <avatar username="carcoster"></avatar>
                    </figure>
                  </div>
                  <div class="media-content">
                    <p class="title is-4">{{ user.username }}</p>
                    <p class="subtitle is-6">{{ user.email }}</p>
                    <p class="subtitle is-6">
                      <b-icon icon="car" size="is-medium"> </b-icon>
                      {{ user.numberOfCars }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import PageTitle from '@/components/PageTitle.vue'

import Avatar from 'vue-avatar'
import { mapState } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    Navbar,
    Avatar,
    PageTitle
  },
  data() {
    return {
      isLoading: false,
      isUpdate: false
    }
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>

<style></style>
